/* eslint-disable import/no-unresolved */
import React from 'react'
import { Vacature } from 'components/elements'

// Third Party
import styled from 'styled-components'

const Row = styled.div`
  margin-top: -120px;
`

const NoResults = styled.h2`
  color: ${props => props.theme.color.text.light};

`

const VacatureOverview = ({ posts }) => {
  return (
    <div className="container">
      <Row className="row">
        {posts.map(({ node }) => (
          <div key={node.wordpress_id} className="col-lg-12 d-flex justify-content-center">
            <Vacature fields={node} />
          </div>
        ))}
        {posts.length === 0 && (
          <div className="py-lg-4 mt-2 py-3 container d-flex justify-content-center align-items-center">
            <NoResults>Geen resultaten</NoResults>
          </div>
        )} 
      </Row>
    </div>
  )
}

export default VacatureOverview