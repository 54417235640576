/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import BlogContainer, { BlogConsumer } from 'components/shared/BlogContainer'
import VacatureOverview from 'components/elements/VacatureOverview'

// Third Party
import styled from 'styled-components'

// Elements
import {
  Hero2,
  HeadingLayout,
  Service,
  Filter,
  Slider
} from 'components/elements'

const Content = styled(ParseContent)`
  h2 {
    font-size: ${props => props.theme.font.size.xxml};
    line-height: 60px;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 40px;
  }
`

const PageTemplate = ({
  data: {
    page: { path, acf, yoast_meta: yoast },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <Hero2 fields={acf.banner} />

      <div className="py-lg-5 mt-5">
        <div className="pt-5">
          <Service 
            fields={acf.blok_1} 
            backgroundTitle={acf.blok_1.background_title}
            direction="right"
          />
        </div>
      </div>
      
      <div className="py-lg-5 mt-5">
        <div className="pt-5">
          <Slider fields={acf.blok_2} />
        </div>
      </div>

      <a id="vacatures" />

      <div className="py-lg-5 mt-5">
        <div className="pt-5">
          <HeadingLayout heading={acf.blok_3.background_title}>
            <BlogContainer
              postType='vacature'
              settings={{
                limit: null
              }}
            >
              <BlogConsumer>
                {({ posts }) => {
                  return (
                    <>
                      <Filter 
                        fields={{
                          description: acf.blok_3.description,
                          vacature: true
                        }}
                      />
                      <VacatureOverview posts={posts} />
                    </>
                  )
                }}
              </BlogConsumer>
            </BlogContainer>
        
            <div className="container mt-4 pt-lg-5 pt-4">
              <Content content={acf.blok_4.description} />
            </div>
          </HeadingLayout>
        </div>
      </div>

      <div className="py-5" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        blok_1 {
          background_title
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        blok_2 {
          background_title
          relation {
            title
            wordpress_id
            acf {
              meta {
                description
                quote_description
                image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 1200) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }

        blok_3 {
          background_title
          description
        }

        blok_4 {
          description
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate